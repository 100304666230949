export class ProjectModel {
  constructor(project = {}) {
    this.type_of_object = project?.type_of_object
    this.type_of_renovation = project?.type_of_renovation
    this.type_of_extension = project?.type_of_extension
    this.square = project?.square
    this.state_of_object = project?.state_of_object
    this.detailsTitle = "Autres informations projet utiles"
    this.roomsRenovate = ["Salle a manger", "Salon", "Salle de bain"]
    this.need_an_architect = project?.need_an_architect
    this.need_an_education = project?.need_an_education
    // this.product_class = project?.product_class
    // this.criterias = project && project.criterias ? project.criterias.split(',').map(i => i.trim()) : []
    this.start_date = project?.start_date ? `${project.start_date}` : null
    this.end_date = project?.end_date
    this.manager_name = project?.manager?.full_name
    // this.budget = project?.budget + ''
    // this.need_credit = project?.need_credit
  }

  static getFormData(project) {
    let formData = { ...project }

    formData.budget = +formData.budget
    // formData.criterias = formData?.criterias.join(',')
    formData.square = +formData.square

    delete formData.roomsRenovate

    return formData
  }
}

export class ProjectAddressModel {
  constructor(project = {}) {
    this.address = project?.address
    this.city = project?.city
  }
}
