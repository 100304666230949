<template>
  <v-row justify="center">
    <v-col v-if="$store.getters['authModule/isSuperUser']" cols="12" md="10">
      <ProjectSwitcher />
    </v-col>
    <v-col md="5">
      <ContactDetails
        :editButtonLoading="editButtonContactLoading"
        @onSave="updateProjectContact"
        :key="rerenderBlock"
      />
      <SummaryOfEstimate
        v-if="projectDicts"
        :project="projectEstimate"
        :editButtonLoading="editButtonProjectLoading"
        @onSave="updateProject"
        :rooms="rooms"
        :key="`${rerenderBlock}-q`"
      />
    </v-col>
    <v-col md="5">
      <QuotasList
        :key="rerenderQuotas"
        :listOfQuotas="quotas"
        @deleteQuota="getQuotas($store.getters['project/getCurrentProjectId'])"
      />
    </v-col>
  </v-row>
</template>

<script>
import quotaListMixin from "@/mixins/quotasList.js";
import { ProjectModel, ProjectAddressModel } from "@/models/project.model.js";
import { ProjectService } from "@/services/project.service.js";
import { UserService } from "@/services/user.service.js";

export default {
  name: "Project",
  mixins: [quotaListMixin],
  components: {
    ContactDetails: () => import("./ContactDetails/ContactDetails.vue"),
    SummaryOfEstimate: () =>
      import("./SummaryOfEstimate/SummaryOfEstimate.vue"),
    QuotasList: () => import("./Quotas/ListOfQuotas/ListOfQuotas.vue"),
    ProjectSwitcher: () => import("./ProjectSwitcher/ProjectSwitcher.vue"),
  },
  data() {
    return {
      projects: null,
      quotas: [],
      projectDicts: null,
      editButtonProjectLoading: false,
      editButtonContactLoading: false,
      rerenderBlock: 1,
    };
  },
  computed: {
    projectEstimate() {
      return this.currentProjectId
        ? new ProjectModel(this.currentProject)
        : new ProjectModel();
    },
    projectAddress() {
      return this.currentProjectId
        ? new ProjectAddressModel(this.currentProject)
        : new ProjectAddressModel();
    },
    setProjectDicts() {
      return this.projectDicts;
    },
    projectId() {
      return this.currentProjectId;
    },
  },
  provide() {
    return {
      projectDicts: () => this.projectDicts,
      projectId: () => this.currentProjectId,
    };
  },
  watch: {
    "$store.state.project.currentUserId": {
      handler() {
        this.rerenderBlock++;
      },
    },
  },
  methods: {
    getProject() {
      return ProjectService.getProjects()
        .then(({ data }) => {
          if (data.length > 0) {
            this.projects = data;
            this.$store.commit("project/SET_PROJECTS", this.projects);
            this.getQuotas(this.$store.getters["project/getCurrentProjectId"]);
            this.getProjectDicts();
          }
        })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => this.$store.commit("project/SET_LOADED_PROJECT"));
    },
    getProjectDicts() {
      ProjectService.getProjectDicts(this.currentProjectId)
        .then(({ data }) => {
          this.projectDicts = data;
        })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        });
    },
    updateProject(model) {
      const currentProjectId =
        this.$store.getters["project/getCurrentProjectId"];
      if (!currentProjectId) return;
      this.editButtonProjectLoading = true;
      ProjectService.updateProjectById(
        currentProjectId,
        ProjectModel.getFormData(model)
      )
        .then(({data}) => {
          this.$store.commit("project/SET_CURRENT_PROJECT", data);
        })
        .catch((response) => {
          this.$store.commit("snackbarModule/SET_ERROR_API", response);
        })
        .finally(() => (this.editButtonProjectLoading = false));
    },
    updateProjectContact({ projectAddress, userPhone }) {
      const currentProjectId =
        this.$store.getters["project/getCurrentProjectId"];
      this.editButtonContactLoading = true;

      let promises = [];

      if (this.$store.getters["authModule/isSuperUser"]) {
        promises.push(
          UserService.updateUser(this.$store.getters["project/getCurrentUserId"], {
            phone: userPhone,
          })
        );
      }

      if (!this.$store.getters["authModule/isSuperUser"]) {
        promises.push(
          UserService.updateMe({
            phone: userPhone,
          })
        );
      }

      if (currentProjectId) {
        promises.push(
          ProjectService.updateProjectById(currentProjectId, projectAddress)
        );
      }

      Promise.all(promises)
        .then(([profile, project]) => {
          if (!this.$store.getters["authModule/isSuperUser"]) {
            this.$store.commit("authModule/SET_USER_PROFILE", profile["data"]);
          }
          if (this.$store.getters["authModule/isSuperUser"]) {
            this.$store.commit("project/SET_CURRENT_USER", profile["data"]);
          }

          if (project) {
            this.projects = [project["data"]];
            this.$store.commit("project/SET_CURRENT_PROJECT", project["data"]);
          }
        })
        .catch((response) => {
          this.$store.commit("snackbarModule/SET_ERROR_API", response);
        })
        .finally(() => {
          this.editButtonContactLoading = false
        });
    },
  },
};
</script>

<style src="./Project.scss" lang="scss" scoped></style>
